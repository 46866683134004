"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidatorRecord = exports.StakeState = exports.Stake = exports.Meta = exports.Lockup = exports.Delegation = exports.Authorized = exports.StakeRecord = exports.StakeInfo = exports.deserializePublicKey = exports.deserializeF64 = exports.MARINADE_BORSH_SCHEMA = void 0;
var marinade_borsh_1 = require("./marinade-borsh");
Object.defineProperty(exports, "MARINADE_BORSH_SCHEMA", { enumerable: true, get: function () { return marinade_borsh_1.MARINADE_BORSH_SCHEMA; } });
var common_1 = require("./common");
Object.defineProperty(exports, "deserializeF64", { enumerable: true, get: function () { return common_1.deserializeF64; } });
Object.defineProperty(exports, "deserializePublicKey", { enumerable: true, get: function () { return common_1.deserializePublicKey; } });
var stake_info_1 = require("./stake-info");
Object.defineProperty(exports, "StakeInfo", { enumerable: true, get: function () { return stake_info_1.StakeInfo; } });
var stake_record_1 = require("./stake-record");
Object.defineProperty(exports, "StakeRecord", { enumerable: true, get: function () { return stake_record_1.StakeRecord; } });
var stake_state_1 = require("./stake-state");
Object.defineProperty(exports, "Authorized", { enumerable: true, get: function () { return stake_state_1.Authorized; } });
Object.defineProperty(exports, "Delegation", { enumerable: true, get: function () { return stake_state_1.Delegation; } });
Object.defineProperty(exports, "Lockup", { enumerable: true, get: function () { return stake_state_1.Lockup; } });
Object.defineProperty(exports, "Meta", { enumerable: true, get: function () { return stake_state_1.Meta; } });
Object.defineProperty(exports, "Stake", { enumerable: true, get: function () { return stake_state_1.Stake; } });
Object.defineProperty(exports, "StakeState", { enumerable: true, get: function () { return stake_state_1.StakeState; } });
var validator_record_1 = require("./validator-record");
Object.defineProperty(exports, "ValidatorRecord", { enumerable: true, get: function () { return validator_record_1.ValidatorRecord; } });
