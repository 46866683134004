class QuestExecution {
    _id
    walletID
    questID
    participants
    status
    cancelStatus
    revertStatus
    timestamp
    finishTimestamp
    stamina
    rewards
    pendingRewards
    claimableRewards
    pendingClaims

    get progress() {
        if(this.status == 'COMPLETE' || this.status == 'CLAIMED' ){
            return 1
        }
        let questLength = this.finishTimestamp - this.timestamp
        let now = Date.now()
        let questActiveFor = now - this.timestamp
        let progress = (questActiveFor / questLength)
        return progress >= 1 ? 1 : progress
    }

    get progressDone() {
        return this.progress >= 1 ? true : false
    }
}

export default QuestExecution 