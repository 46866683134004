"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.proportionalBN = exports.unstakeNowFeeBp = void 0;
const anchor_1 = require("@project-serum/anchor");
/**
 * Compute a linear fee base on liquidity amount.
 * fee(0) = max fee -> fee(x >= target) = min fee
 *
 * @param {number} lpMinFeeBasisPoints
 * @param {number} lpMaxFeeBasisPoints
 * @param {BN} lpLiquidityTarget
 * @param {BN} lamportsAvailable
 * @param {BN} lamportsToObtain
 */
function unstakeNowFeeBp(lpMinFeeBasisPoints, lpMaxFeeBasisPoints, lpLiquidityTarget, lamportsAvailable, lamportsToObtain) {
    // if trying to get more than existing
    if (lamportsToObtain.gte(lamportsAvailable)) {
        return lpMaxFeeBasisPoints;
    }
    // result after operation
    const lamportsAfter = lamportsAvailable.sub(lamportsToObtain);
    // if GTE target => min fee
    if (lamportsAfter.gte(lpLiquidityTarget)) {
        return lpMinFeeBasisPoints;
    }
    else {
        const delta = lpMaxFeeBasisPoints - lpMinFeeBasisPoints;
        return lpMaxFeeBasisPoints
            - proportionalBN(new anchor_1.BN(delta), lamportsAfter, lpLiquidityTarget).toNumber();
    }
}
exports.unstakeNowFeeBp = unstakeNowFeeBp;
/**
 * Returns `amount` * `numerator` / `denominator`.
 * BN library we use does not handle fractions, so the value is `floored`
 *
 * @param {BN} amount
 * @param {BN} numerator
 * @param {BN} denominator
 */
function proportionalBN(amount, numerator, denominator) {
    if (denominator.isZero()) {
        return amount;
    }
    return amount.mul(numerator).div(denominator);
}
exports.proportionalBN = proportionalBN;
