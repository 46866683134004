import axios from "axios";
import { PublicKey, Transaction, TransactionInstruction } from "@solana/web3.js";
import sha256 from 'crypto-js/sha256';
import {Buffer} from 'buffer';

const domainURL = process.env.REACT_APP_DOMAIN || 'http://localhost:5050/api';
const userURL = domainURL + "/user";

const stringifyParams = (data) => {
    let keys = Object.keys(data).sort();
    let params = [];

    for (let i in keys) {
      let key = keys[i];
      let value = data[key];

      params.push(key + '=' + (Array.isArray(value) ? value.join(',') : value));
    }

    return sha256( params.join('&') );
  };

class WalletUtils{
  static verifyWallet = async (connection, publicKey, wallet, action, data, usingLedger = false, signTransaction) => {
    let userResult = await axios.get(userURL + "?wallet=" + publicKey.toString());
    data.action = action;

    if (userResult.data.success) {
      data.user = userResult.data.user;
      let challenge = stringifyParams(data);

      if (usingLedger) {
        return await WalletUtils.verifyTransaction(connection, publicKey, challenge, signTransaction);
      } else {
        return await WalletUtils.verifyMessage(wallet, challenge)
      }
    }
  };

  static verifyTransaction = async (connection, publicKey, challenge, signTransaction) => {
    try {
      const recentBlockhash = (await connection.getRecentBlockhash('finalized')).blockhash;
      const instruction = new TransactionInstruction({
        keys: [{ pubkey: publicKey }],
        programId: new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr'),
        data: Buffer.from(challenge.toString()),
      });

      let transaction = new Transaction({
        feePayer: publicKey,
        recentBlockhash: recentBlockhash
      }).add(instruction);

      transaction = await signTransaction(transaction);

      return [transaction.signatures[0].signature, recentBlockhash];
    } catch (e) {
      console.log(e);
      return [null, null];
    }
  };

  static verifyMessage = async (wallet, challenge) => {
    const message = "Please sign this message for proof of address ownership: " + challenge;
    const data = new TextEncoder().encode(message);
    let signature = await wallet.adapter.signMessage(data, 'utf8');

    return [signature, null];
  };
}  

export default WalletUtils;