import './_AuctionHouse_Events.css'
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import bs58 from "bs58";
import WalletUtils from '../utils/WalletUtils';
import Utils from "../utils/Utils";
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { intervalToDuration, formatDuration, format, form } from 'date-fns'

const domainURL = process.env.REACT_APP_DOMAIN || 'http://localhost:5050/api';

const AuctionHousePastEvent = (props) => {
  return (
    <>
    
    <Grid container spacing={4}>
      <Grid item xs={24} md={6} lg={4}><img src={props.auction.image} className="auction-image has-border-radius-lg" /></Grid>
      <Grid item xs={24} md={18} lg={20}>
        <Grid container justifyContent="space-between" alignItems="center" className="auction-detail">
          <Grid item>
            <div className="auction-tag is-raffle is-uppercase">
              <img src="/img/icon-plane-boarding-pass.svg" alt="Raffle icon" title="Raffle" /><span className="auction-tag-type">Raffle</span>
            </div>
          </Grid>
        </Grid>

        <h2 className="auction-title has-text-primary has-font-gooper-bold">{props.auction.title}</h2>
        {props.auction.author && 
          <div className="auction-by">by {props.auction.authorLink ? <a href={props.auction.authorLink}>{props.auction.author}</a> : props.auction.author}</div>
        }
        {props.auction.description && 
          <div className="auction-description">{props.auction.description}</div>
        }
        <div className="auction-detail-container">
        <Grid container justifyContent="space-between" className="auction-detail">
            <Grid item><strong>Cost per ticket</strong></Grid>
            <Grid item>{props.auction.ticketPrice} $ASTRA</Grid>
          </Grid>
          <Grid container justifyContent="space-between" className="auction-detail">
            <Grid item><strong>Total entries</strong></Grid>
            <Grid item>{props.auction.totalTickets} entries</Grid>
          </Grid>
          <Grid container justifyContent="space-between" className="auction-detail">
            <Grid item><strong>Winner{props.auction.winners?.length > 1 ? 's' : ''}</strong></Grid>
            {props.auction.winners && 
              <Grid item>{props.auction.winners.map(winner => <span>{winner.slice(0, 4) + '...' + winner.slice(-4)}<br /></span>)}</Grid>
            }
            {!props.auction.winners && <Grid item>Pending draw</Grid> }
          </Grid>
        </div>
      </Grid>
    </Grid>
    </>
  )
}

export default AuctionHousePastEvent;