import React, { useMemo } from 'react';
import Grid from '@mui/material/Grid';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
    GlowWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import Dashboard from './Dashboard';
import Office from './Office';
import Header from './components/Header';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import FieldWork from './FieldWork';
import AuctionHouse from './sections/AuctionHouse';
import { GrimsProvider } from './GrimsProvider';
import SectionQuest from './SectionQuest';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const Wallet = () => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;
    const solanaRPC = process.env.REACT_APP_RPC_NODE || 'https://ssc-dao.genesysgo.net/';

    // const network = WalletAdapterNetwork.Devnet;

    // You can also provide a custom RPC endpoint.
    // const endpoint = useMemo(() => clusterApiUrl(network), [network]);
    const endpoint = useMemo(() => solanaRPC);

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking and lazy loading --
    // Only the wallets you configure here will be compiled into your application, and only the dependencies
    // of wallets that your users connect to will be loaded.
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SolflareWalletAdapter(),
            new SlopeWalletAdapter(),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletWalletAdapter({ network }),
            new SolletExtensionWalletAdapter({ network }),
        ],
        [network]
    );

    var result = (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                    <GrimsProvider>
                    <Router>
                    <>
                        <Header />
                        <Grid container columns={24} flex={1} className="app-content">
                            <Grid item xs={24}>
                                <Routes>
                                    <Route path="/field-work/:id" element={<SectionQuest />}></Route>
                                    <Route path="/field-work" element={<FieldWork />}></Route>
                                    <Route path="/auction-house" element={<AuctionHouse />}></Route>
                                    <Route path="/office" element={<Office />}></Route>
                                    <Route path="/" element={<Dashboard />}></Route>
                                </Routes>
                            </Grid>
                        </Grid>
                    </>
                    </Router>
                    </GrimsProvider>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );

    return result;
};

export default Wallet;